
const Loading = () => {

    return (
        <div>
            <i className="fas fa-spinner fa-spin"></i>
        </div>
    )
}

export { Loading };